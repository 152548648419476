function getRouteObject(notification) {
  const { id, type } = notification.action;
  switch (type) {
    case 'Profile':
      return {
        name: 'Profile',
        params: {
          type: 'address',
          leaveId: id,
        },
      };

    case 'Contact':
      return {
        name: 'Contacts',
        params: {
          contactId: id,
        },
      };

    case 'Address':
      return {
        name: 'Profile',
        params: {
          type: 'address',
        },
      };

    case 'HcmsSuPreference':
      return {
        name: 'Preferences',
      };

    case 'ScheduledBooking':
      return {
        name: 'Booking Details',
        params: {
          bookingId: id,
        },
      };

    case 'BookingChangeRequest':
      return {
        name: 'Booking Change',
        params: {
          changeRequestId: id,
        },
      };

    case 'EngineRun':
      return {
        name: 'Bookings Notification',
        params: {
          engineRunId: id,
        },
      };

    default:
      return undefined;
  }
}

export { getRouteObject };
