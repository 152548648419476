<template>
  <div v-loading="loading.bookingDetails">
    <v-toolbar v-if="showNav" flat color="primary" dark>
      <v-btn icon @click="goBack()">
        <v-icon>arrow_back</v-icon>
      </v-btn>
      <v-toolbar-title>Booking Details</v-toolbar-title>
    </v-toolbar>

    <v-container fluid>
      <v-layout row class="pb-2">
        <v-flex xs4>
          <strong>Date:</strong>
        </v-flex>
        <v-flex xs8>
          {{ formatDate(booking.start) }}
        </v-flex>
      </v-layout>

      <v-layout row class="pb-2" align-end>
        <v-flex xs4>
          <strong>Time:</strong>
        </v-flex>
        <v-flex xs8 layout align-end> {{ formatTime(booking.start) }} - {{ formatTime(booking.end) }} </v-flex>
      </v-layout>

      <v-layout row class="pb-2">
        <v-flex xs4>
          <strong>Type:</strong>
        </v-flex>
        <v-flex xs8>
          {{ bookingTypeUtils.toLabel(booking.bookingType) }}
        </v-flex>
      </v-layout>
    </v-container>

    <v-divider />
    <v-container v-loading="loading.careWorkers" fluid>
      <h6 class="title">Care Workers</h6>
      <v-layout class="mt-3">
        <v-flex class="text-xs-center" v-for="(cw, index) in careWorkers" :key="index" xs6>
          <a :href="`tel:${cw.telephone}`" class="no-link">
            <v-avatar :size="108" class="my-2">
              <v-img :src="cw.profileImageUrl" />
            </v-avatar>
            <div>{{ cw.name }}</div>
            <div class="primary--text telephone" v-if="cw.telephone">
              <v-icon small color="primary">call</v-icon>
              {{ cw.telephone }}
            </div>
          </a>
        </v-flex>
      </v-layout>
    </v-container>

    <v-divider />
  </div>
</template>

<script>
import { bookingTypeUtils, titleUtils } from 'hcms-mc-utils';
import { get as getAttachment } from 'hcms-transforms/attachment';
import { get as getBooking } from 'hcms-transforms/booking';
import { get as getUser } from 'hcms-transforms/user_list';

import { VAvatar, VImg, VToolbar, VToolbarTitle } from 'vuetify/lib';

export default {
  name: 'BookingDetails',

  components: {
    VToolbar,
    VToolbarTitle,
    VAvatar,
    VImg,
  },

  props: ['bookingId'],

  constants: {
    bookingTypeUtils,
  },

  data() {
    return {
      loading: {
        bookingDetails: false,
        careWorkers: false,
      },

      booking: {
        suId: '',
        bookingAllocation: [],

        date: '',
        startTime: '',
        endTime: '',

        bookingTasks: [],
      },

      careWorkers: [],
    };
  },

  methods: {
    async getCareWorkerDetails() {
      try {
        const promises = this.booking.bookingAllocation.map(async (allocation) => {
          const userName = allocation.cwUserName;
          const user = await getUser(userName);
          const cwDetails = {
            name: `${titleUtils.toLabel(user.title)} ${user.firstName} ${user.lastName}`,
            telephone: user.telephone,
            profileImageUrl: '/img/no-photo-man.png',
          };

          if (!user.profileImageId) {
            return cwDetails;
          }

          const attachment = await getAttachment(user.profileImageId);
          cwDetails.profileImageUrl = attachment.url;
          return cwDetails;
        });
        this.careWorkers = await Promise.all(promises);
      } catch (err) {
        this.$notify(err, 'error');
      }
    },

    async init() {
      this.loading.bookingDetails = true;
      try {
        this.booking = await getBooking(this.bookingId);
        this.getCareWorkerDetails();
      } catch (err) {
        this.$notify(err, 'error');
      }
      this.loading.bookingDetails = false;
    },
  },

  async created() {
    this.init();
  },
};
</script>

<style scoped>
.mar-detail {
  padding-top: 4px;
  margin-top: 8px;
  margin-left: 24px;
  margin-bottom: 0px;
}

.telephone {
  font-size: 14px;
}
</style>
