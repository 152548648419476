<template>
  <div>
    <v-toolbar v-if="showNav" flat color="primary" dark>
      <v-btn icon @click="goBack()">
        <v-icon>arrow_back</v-icon>
      </v-btn>
      <v-toolbar-title>Bookings Notification</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>

    <v-layout align-center class="pa-3">
      <h5 class="headline">Allocated Bookings</h5>
    </v-layout>
    <bookings v-loading="loading.init" v-model="bookings" />
  </div>
</template>

<script>
import Bookings from 'components/Bookings.vue';
import { bookingAllocationStatusUtils, bookingStatusUtils } from 'hcms-mc-utils';
import { getAll as getBookings } from 'hcms-transforms/booking';
import { get as getUser } from 'hcms-transforms/user_list';
import { VToolbar, VToolbarTitle } from 'vuetify/lib';

const { NEW, RESCHEDULE_REQUEST } = bookingStatusUtils.DICT;
const { ALLOCATED } = bookingAllocationStatusUtils.DICT;

export default {
  name: 'Overview',

  props: {
    engineRunId: {
      required: true,
      type: Number,
    },
  },

  components: {
    Bookings,
    VToolbar,
    VToolbarTitle,
  },

  data() {
    return {
      loading: {
        init: false,
      },
      bookings: [],
    };
  },

  computed: {
    params() {
      return {
        service_user_user_name: this.userName,
        is_notified: true,
        booking_allocation_result: [ALLOCATED].toString(),
        booking_status: [NEW, RESCHEDULE_REQUEST].toString(),
        engine_run_id: this.engineRunId,
        sort: 'start_date',
      };
    },
  },

  methods: {
    async init() {
      const getCareWorkerName = async (cwUserName) => {
        if (!cwUserName) {
          return '';
        }
        const user = await getUser(cwUserName);
        const { title, firstName, lastName } = user;
        return `${title} ${firstName} ${lastName}`;
      };

      this.loading.init = true;

      try {
        const bookings = await getBookings(this.params);
        const bookingPromises = bookings.map(async (booking) => {
          const cwName = await getCareWorkerName(booking.bookingAllocation[0].cwUserName);
          return {
            ...booking,
            bookingTitle: `${this.formatDate(booking.start)} - ${this.formatTime(booking.start)} - ${cwName}`,
          };
        });
        this.bookings = await Promise.all(bookingPromises);
      } catch (err) {
        this.$notify(err, 'error');
      }

      this.loading.init = false;
    },
  },

  created() {
    this.init();
  },
};
</script>

<style lang="scss" scoped>
.report-btn {
  margin-right: -12px;
}
</style>
