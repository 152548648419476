<template>
  <div v-loading="loading">
    <v-list v-if="bookingList.length > 0">
      <template v-for="(booking, index) in bookingList">
        <v-list-tile
          :key="booking.bookingId"
          :to="{
            name: 'Booking Details',
            params: { bookingId: booking.bookingId },
          }">
          <v-layout justify-space-between align-center>
            <span>
              {{ booking.bookingTitle }}
              <v-icon v-if="booking.lastModified.isAfter(lastLogin)" class="success--text">new_releases</v-icon>
            </span>
            <v-spacer></v-spacer>
            <v-icon color="grey">info</v-icon>
          </v-layout>
        </v-list-tile>
        <v-divider v-if="index !== bookingList.length - 1" :key="index + 1"> </v-divider>
      </template>
    </v-list>
    <v-layout v-else class="pa-2 grey--text" align-center justify-center> No Bookings </v-layout>
  </div>
</template>

<script>
import { currentUser } from 'auth-utils';
import { VList, VListTile, VDivider } from 'vuetify/lib';

export default {
  name: 'Bookings',
  model: {
    prop: 'value',
  },

  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },

  components: {
    VList,
    VListTile,
    VDivider,
  },

  constants: {
    lastLogin: currentUser.lastLogin,
  },

  data() {
    return {
      loading: false,
      bookingList: [],
    };
  },

  computed: {
    model() {
      return this.value;
    },
  },

  methods: {
    async initBookingData() {
      this.loading = true;
      this.bookingList = this.model;
      this.loading = false;
    },
  },

  watch: {
    async model() {
      await this.initBookingData();
    },
  },

  async created() {
    await this.initBookingData();
  },
};
</script>
