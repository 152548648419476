<template>
  <v-card class="confirmation-modal">
    <v-card-title>
      <h3 class="title">Confirm</h3>
    </v-card-title>

    <v-card-text>
      {{ description }}
    </v-card-text>

    <v-layout class="px-1 pb-2" justify-end>
      <v-btn :color="primaryAction.color" flat @click="confirm()">
        {{ primaryAction.title }}
      </v-btn>
    </v-layout>
  </v-card>
</template>
<script>
import { VCard, VCardTitle, VCardText } from 'vuetify/lib';

export default {
  name: 'ConfirmationModal',
  props: {
    title: {
      type: String,
      default: 'Confirm',
    },
    description: {
      type: String,
      required: true,
    },
    primaryAction: {
      type: Object,
      default() {
        return {
          title: 'Confirm',
          color: 'error',
        };
      },
    },
  },
  components: {
    VCard,
    VCardTitle,
    VCardText,
  },

  methods: {
    confirm() {
      this.$emit('close', true);
    },
  },
};
</script>
<style lang="scss" scoped>
.confirmation-modal {
  width: 290px;
}
</style>
