import { round, sortArrayBy } from 'global-utils';

import axios from 'axios';
import moment from 'moment';

function transformResponse(allocations) {
  return sortArrayBy(
    allocations.map((allocation) => ({
      cwUserName: allocation.careworker_user_name,
      allocationId: allocation.booking_allocation_id,
      includeInPayslip: Boolean(allocation.include_in_payslip),
      quality: allocation.booking_quality,
      travelTime: allocation.travel_time,
      allocationType: allocation.allocation_type_mc_id,
      preferredCwType: allocation.hard_preferred_cw_mc_id,
      preLockState: allocation.fixed_cw_mc_id,
      certificateExpiryId: allocation.certificate_expiry_id,
      cwTerminationId: allocation.cw_termination_id,
      cwSuspensionId: allocation.cw_suspension_id,
      cwLeaveId: allocation.cw_leave_id,
      actualDuration: allocation.actual_duration,
      actualStartTime:
        allocation.actual_start_time &&
        allocation.actual_start_time.split(':').slice(0, 2).join(':'),
      payslipId: allocation.payslip_id,
      availabilityHr: round(allocation.availability_hr_per_week || 0, 0.01),
      utilizedHr: round(allocation.week_utilized_hr, 0.01),
      visitCount: allocation.visit_count,
      isMaster: allocation.is_master,
      eta: allocation.eta,
      preArrivalStatus: allocation.pre_arrival_status_mc_id,
      isReached: allocation.is_reached,
      isVisitCompleted: allocation.is_visit_completed,
      isGpsSignalLost: allocation.is_gps_signal_lost,
      preArrivalTimestamp: allocation.pre_arrival_timestamp
        ? moment(allocation.pre_arrival_timestamp)
        : null,
      arrivalTimestamp: allocation.arrival_timestamp
        ? moment(allocation.arrival_timestamp)
        : null,
      nad: (allocation.nad ?? []).map((n) => ({
        start: moment(n.start_time),
        duration: n.duration,
        hasSensorData: n.has_sensor_data,
      })),
      isVisitNad: allocation.is_vr_nad,
      visitTimestamp: allocation.vr_timestamp
        ? moment(allocation.vr_timestamp)
        : null,
      visitDurationType: allocation.actual_duration_type,
    })),
    '-isMaster',
  );
}

function transformRequest(allocations) {
  return {
    booking_allocation: allocations.map((allocation) => ({
      careworker_user_name: allocation.cwUserName,
      sequence: allocation.index,
    })),
  };
}

function put(bookingId, allocation, params) {
  const url = `api/booking/${bookingId}/allocation`;
  const request = transformRequest(allocation);
  return axios.put(url, request, { params });
}

export { transformResponse, transformRequest, put };
