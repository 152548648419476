import axios from 'axios';
import moment from 'moment';
import { sortArrayBy } from 'global-utils';
import { transformResponse as transformAllocationResponse } from './allocation';
import { transformResponse as transformConsumables } from '../su/weekly_booking/consumable';

function transformResponse(booking) {
  const bookingAllocation = transformAllocationResponse(
    booking.booking_allocation,
    booking,
  );
  const allocationQuality =
    bookingAllocation[0].quality === null
      ? null
      : (bookingAllocation.reduce((acc, val) => acc + val.quality, 0) * 10) /
        bookingAllocation.length;

  const sortedTasks = sortArrayBy(booking.booking_tasks, 'sequence').map(
    (task) => ({
      title: task.title,
    }),
  );

  return {
    suUserName: booking.service_user_user_name,
    cwUserName: bookingAllocation[0].cwUserName,
    bookingId: booking.scheduled_booking_id,
    bookingCode: booking.booking_code,
    includeInInvoice: Boolean(booking.include_in_invoice),
    start: moment(booking.started_date_time),
    end: moment(booking.finished_date_time).add(1, 's'),
    bookingType: booking.booking_type_mc_id,
    consumables: booking.consumables
      ? booking.consumables.map(transformConsumables)
      : [],
    fixedCws: booking.fixed_cws,
    skills: booking.skills_required ?? [],
    noOfCw: booking.no_of_cw,
    note: booking.wb_note,
    history: booking.note,
    isNotified: booking.is_notified,
    isUnresolved: booking.is_unresolved,
    allocationQuality,
    exceptions:
      typeof booking.exceptions === 'string'
        ? booking.exceptions.split(',')
        : [],
    allocationStatus: booking.booking_allocation_result_mc_id,
    bookingAllocation,
    bookingStatus: booking.booking_status_mc_id,
    tasks: sortedTasks,

    medications:
      booking.medications.map((obj) => ({
        medicationId: obj.medication_id,
        slotId: obj.medication_slot_id,
        weekday: obj.weekday,
        isGraceCovered: obj.is_covered_by_grace_period,
      })) ?? [],
    showFluidBalance: booking.enable_fluid_balance_functionality,
    weeklyBookingId: booking.weekly_booking_id,
    changeRequestId: booking.bcr,
    reportId: booking.engine_run_id,
    lastModified: moment(booking.last_modified_date),
    certificateExpiryId: booking.certificate_expiry_id,
    cwTerminationId: booking.cw_termination_id,
    cwSuspensionId: booking.cw_suspension_id,
    cwLeaveId: booking.cw_leave_id,
    suAddressId: booking.su_address_id,
    invoiceId: booking.invoice_id,
    fundingSourceId: booking.funding_source_id,
    fundingSubtype: booking.funding_sub_type,
  };
}

function transformRequest(booking) {
  return {
    booking_status_mc_id: booking.bookingStatus,
    is_unresolved: booking.isUnresolved,
    include_in_invoice: booking.includeInInvoice,
  };
}

async function getAll(queryParams) {
  const url = 'api/booking';
  const response = await axios.get(url, { params: queryParams });
  const results = response.data.results.map(transformResponse);
  results.total = response.data.count;
  return results;
}
async function get(bookingId) {
  const url = `api/booking/${bookingId}`;
  const response = await axios.get(url);
  return transformResponse(response.data.booking);
}

async function put(bookingId, booking) {
  const url = `api/booking/${bookingId}`;
  const request = transformRequest(booking);
  await axios.put(url, request);
}

export { getAll, get, put, transformResponse };

export default {
  transformResponse,
};
