import Vue from 'vue';
import VeeValidate from 'vee-validate';
import moment from 'moment';

import { formatDate } from 'global-utils';
import { PHONE_UK, POSTAL_CODE_UK } from 'hcms-constants/REGEX';

VeeValidate.Validator.localize('en', {
  messages: {
    required() {
      return 'Required';
    },
    regex() {
      return 'Invalid Format';
    },
    is_not(fieldName, value) {
      return `Must not be ${value}`;
    },
  },
});

VeeValidate.Validator.extend('postal_code', {
  getMessage: () => 'Must be a valid UK postal code',
  validate: (value) => String(value).search(POSTAL_CODE_UK) !== -1,
});

VeeValidate.Validator.extend('phone', {
  getMessage: () => 'Must be a valid UK phone number',
  validate: (value) => String(value).search(PHONE_UK) !== -1,
});

VeeValidate.Validator.extend('gt', {
  getMessage: (fieldName, min) => `Must be more than ${min}`,
  validate: (value, [min]) => value > min,
});

VeeValidate.Validator.extend('isAfter', {
  getMessage: (fieldName, [minDate]) => `Must be after ${formatDate(minDate)}`,
  validate: (value, [minDate]) => moment(value).isAfter(minDate),
});

VeeValidate.Validator.extend('lt', {
  getMessage: (fieldName, max) => `Must be less than ${max}`,
  validate: (value, [max]) => value < max,
});

VeeValidate.Validator.extend('gte', {
  getMessage: (fieldName, min) => `Must be ${min} or more`,
  validate: (value, [min]) => value >= min,
});

VeeValidate.Validator.extend('lte', {
  getMessage: (fieldName, max) => `Must be ${max} or less`,
  validate: (value, [max]) => value <= max,
});

VeeValidate.Validator.extend('isMultiple', {
  getMessage: (fieldName, multiplier) => `Must be a multiple of ${multiplier}`,
  validate: (value, [multiplier]) => value % multiplier === 0,
});

VeeValidate.Validator.extend('insideCriticalPeriod', {
  getMessage: () => 'Must be inside critical period',
  validate: (value) => value <= moment().endOf('week').add(1, 'week'),
});

VeeValidate.Validator.extend('outsideCriticalPeriod', {
  getMessage: () => 'Must be outside critical period',
  validate: (value) => value > moment().endOf('week').add(1, 'week'),
});

Vue.use(VeeValidate, {
  errorBagName: 'errors',
  fieldsBagName: 'veeFields',
});

Vue.use(VeeValidate);
