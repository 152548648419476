<template>
  <div v-loading="loading">
    <v-toolbar flat color="primary" dark>
      <v-btn icon @click="goBack()">
        <v-icon>arrow_back</v-icon>
      </v-btn>
      <v-toolbar-title>Profile</v-toolbar-title>
    </v-toolbar>

    <v-container fluid>
      <v-layout justify-space-between>
        <div class="mr-4 text-xs-center">
          <v-avatar :size="120">
            <img :src="profileImageUrl" />
          </v-avatar>
          <div class="title mt-2">
            {{ titleUtils.toLabel(user.profile.title) }}
            {{ user.profile.firstName }}
            {{ user.profile.lastName }}
          </div>
        </div>

        <v-flex class="mt-2">
          <v-layout>
            <v-icon class="mr-2">mail</v-icon>
            {{ user.email }}
          </v-layout>

          <v-layout class="mt-1">
            <v-icon class="mr-2">phone</v-icon>
            {{ user.profile.telephone1 }}
            {{ user.profile.telephone2 }}
          </v-layout>

          <v-layout class="mt-1">
            <v-icon class="mr-2 st-1">cake</v-icon>
            {{ formatDate(user.profile.dob) }}
          </v-layout>
          <v-layout class="mt-1">
            <v-icon class="mr-2">directions</v-icon>
            <span>
              <span> {{ address.street1 }}</span
              ><span v-if="address.street2">, {{ address.street2 }}</span
              ><span>, {{ address.postCode }} </span>
            </span>
          </v-layout>
        </v-flex>
      </v-layout>
      <hr class="anti-container form-grid__hr" />
      <div>
        <div class="title mb-3">Other Info</div>
        <div>
          <v-layout justify-space-between>
            <v-flex class="font-weight-bold">Marital Status</v-flex>
            <v-flex class="text-xs-right">{{ maritalStatusUtils.toLabel(user.profile.maritalStatus) }}</v-flex>
          </v-layout>
          <v-layout justify-space-between>
            <v-flex class="font-weight-bold">Sex</v-flex>
            <v-flex class="text-xs-right">{{ genderUtils.toLabel(user.profile.sex) }}</v-flex>
          </v-layout>
          <v-layout justify-space-between>
            <v-flex class="font-weight-bold">Smoker</v-flex>
            <v-flex class="text-xs-right">{{ antiBooleanUtils.toLabel(user.profile.isSmoker) }}</v-flex>
          </v-layout>
          <v-layout justify-space-between>
            <v-flex class="font-weight-bold">Have Pets?</v-flex>
            <v-flex class="text-xs-right">{{ user.serviceUser.hasPets }}</v-flex>
          </v-layout>
          <v-layout justify-space-between>
            <v-flex class="font-weight-bold">National Insurance #</v-flex>
            <v-flex class="text-xs-right">{{ user.profile.niNumber }}</v-flex>
          </v-layout>
          <v-layout justify-space-between>
            <v-flex class="font-weight-bold">NHS #</v-flex>
            <v-flex class="text-xs-right">{{ user.profile.nhsNo }}</v-flex>
          </v-layout>
          <v-layout justify-space-between>
            <v-flex class="font-weight-bold">Religion</v-flex>
            <v-flex class="text-xs-right">{{ religionUtils.toLabel(user.profile.religion) }}</v-flex>
          </v-layout>
          <v-layout justify-space-between>
            <v-flex class="font-weight-bold">Ethnicity</v-flex>
            <v-flex class="text-xs-right">{{ ethnicityUtils.toLabel(user.profile.ethnicity) }}</v-flex>
          </v-layout>
          <v-layout justify-space-between>
            <v-flex class="font-weight-bold">Language</v-flex>
            <v-flex class="text-xs-right">
              {{
                [user.profile.primaryLanguage, user.profile.secondaryLanguage]
                  .filter((val) => val)
                  .map((val) => languageUtils.toLabel(val))
                  .join(', ')
              }}
            </v-flex>
          </v-layout>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
import { VAvatar, VToolbar, VToolbarTitle } from 'vuetify/lib';

import { get as getAttachment } from 'hcms-transforms/attachment';
import { get as getSu } from 'hcms-transforms/su';
import { getAll as getAddresses } from 'hcms-transforms/user/address';

import {
  accountTypeUtils,
  ethnicityUtils,
  genderUtils,
  languageUtils,
  maritalStatusUtils,
  religionUtils,
  titleUtils,
} from 'hcms-mc-utils';

import { currentUser } from 'auth-utils';
import { antiBooleanUtils } from 'hcms-const-utils';

export default {
  name: 'Profile',
  components: {
    VAvatar,
    VToolbar,
    VToolbarTitle,
  },

  constants: {
    accountTypeUtils,
    antiBooleanUtils,
    ethnicityUtils,
    genderUtils,
    languageUtils,
    maritalStatusUtils,
    religionUtils,
    titleUtils,
    userName: currentUser.userName,
  },

  computed: {
    isEdited() {
      return !this.equalJSON(this.user, this.originalUser);
    },
  },

  data() {
    return {
      loading: false,
      disableEdit: true,

      user: {
        email: '',
        serviceUser: {},
        profile: {},
      },
      address: {},
      profileImageUrl: '/img/no-photo-man.png',
    };
  },

  methods: {
    async init() {
      this.loading = true;

      try {
        const suPromise = getSu(this.userName);
        const addressPromise = getAddresses(this.userName, {
          is_current_address: true,
        });
        await Promise.all([suPromise, addressPromise]);
        this.user = await suPromise;
        const addresses = await addressPromise;
        this.address = addresses[0];

        if (this.user.profile.profileImageId.length) {
          const profileImageAttachment = await getAttachment(this.user.profileImageId[0]);
          this.profileImageUrl = profileImageAttachment.url;
        }
      } catch (error) {
        this.$notify('Could not fetch Profile', 'error');
      }

      this.loading = false;
    },
  },

  created() {
    this.init();
  },
};
</script>

<style lang="scss" scoped>
.profile-image-edit {
  right: 0;
  bottom: 0;
}
</style>
