<template>
  <div v-loading="loading">
    <v-toolbar v-if="showNav" flat color="primary" dark>
      <v-btn icon @click="goBack()">
        <v-icon>arrow_back</v-icon>
      </v-btn>
      <v-toolbar-title>Booking Preferences</v-toolbar-title>
    </v-toolbar>

    <v-container class="pt-0" fluid>
      <v-layout row v-for="pref in preferences.preferences" :key="pref.preferenceType">
        <v-flex xs6>
          <v-checkbox :label="pref.preferenceLabel" v-model="pref.preferenceValue" hide-details disabled> </v-checkbox>
        </v-flex>
        <div v-if="pref.allowHard">
          <v-radio-group v-model="pref.incorporationType" row hide-details>
            <v-radio
              disabled
              hide-details
              v-for="option in INCORPORATION_TYPES"
              :key="option.value"
              :label="option.label"
              :value="option.value"></v-radio>
          </v-radio-group>
        </div>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import { get as getPreferences } from 'hcms-transforms/su/preference';

import { bookingTypeUtils, preferenceIncorporationTypeUtils, transportTypeUtils } from 'hcms-mc-utils';

import { VCheckbox, VRadio, VRadioGroup, VToolbar, VToolbarTitle } from 'vuetify/lib';

export default {
  name: 'Preferences',
  components: {
    VToolbar,
    VToolbarTitle,
    VCheckbox,
    VRadioGroup,
    VRadio,
  },

  constants: {
    BOOKING_TYPES: bookingTypeUtils.OPTIONS,
    TRANSPORT_TYPES: transportTypeUtils.OPTIONS,
    INCORPORATION_TYPES: preferenceIncorporationTypeUtils.OPTIONS,
  },

  data() {
    return {
      loading: false,

      preferences: {
        bookingTypes: [],
        transportType: '',
        preferences: [],
      },
    };
  },

  methods: {
    async init() {
      this.loading = true;
      try {
        this.preferences = await getPreferences(this.userName);
      } catch (error) {
        this.$notify(error, 'error');
      }
      this.loading = false;
    },
  },

  created() {
    this.init();
  },
};
</script>
