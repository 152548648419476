function transformResponse(consumable) {
  return {
    consumableType: consumable.consumable_type_mc_id,
    quantity: consumable.quantity,
  };
}

function transformRequest(consumable) {
  return {
    consumable_type_mc_id: consumable.consumableType,
    quantity: consumable.quantity,
  };
}

export { transformResponse, transformRequest };
