import './setup/checkAuthentication';
import './setup/locale';
import './setup/validator';
import './setup/axios';
import 'vuetify/src/stylus/app.styl';
import 'material-design-icons-iconfont/dist/material-design-icons.css';
import '@/assets/scss/hcms.scss';

import Vuetify, {
  VApp,
  VBottomNav,
  VBtn,
  VContainer,
  VContent,
  VDivider,
  VFlex,
  VForm,
  VIcon,
  VLayout,
  VSpacer,
  VTextField,
} from 'vuetify/lib';

import App from '@/App.vue';
import Cookies from 'js-cookie';
import GlobalUtils from 'global-utils';
import Loading from 'directives/Loading';
import Notify from 'utils/notify';
import ShowModal from 'vue-modal-promise';
import Vue from 'vue';
import VueConstants from 'vue-constants';
import { currentUser } from 'auth-utils';
import router from '@/router';
import setDefaultTimezone from './setup/timezone';

Vue.use(VueConstants);
Vue.use(GlobalUtils);
Vue.use(ShowModal);
Vue.use(Notify);
Vue.use(Loading);

Vue.use(Vuetify, {
  components: {
    VApp,
    VBottomNav,
    VContent,
    VDivider,
    VForm,
    VLayout,
    VFlex,
    VTextField,
    VContainer,
    VBtn,
    VSpacer,
    VIcon,
  },
});

Vue.config.productionTip = false;

Vue.prototype.userName = currentUser.userName;
Vue.prototype.showNav = Cookies.get('hideNav') !== 'true';
Vue.prototype.goBack = () => {
  try {
    router.go(-1);
  } catch (err) {
    router.push('/');
  }
};

setDefaultTimezone().then(() => {
  /* eslint-disable no-new */
  new Vue({
    el: '#app',
    router,
    render: (h) => h(App),
  });
});
