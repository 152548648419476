import axios from 'axios';
import {
  preferenceTypeUtils,
  preferenceIncorporationTypeUtils,
} from 'hcms-mc-utils';

const SOFT = preferenceIncorporationTypeUtils.toValue('Soft');
const NAMED_CW_PREF = preferenceTypeUtils.toValue('Named Care Worker');

function transformResponse(preferenceData) {
  const getPreference = (preferenceType) =>
    preferenceData.find(
      (pref) => pref.preference_type_mc_id === preferenceType,
    );

  const preferences = preferenceTypeUtils.OPTIONS.filter(
    (opt) => opt.meta.forSu && !opt.meta.special,
  ).map((opt) => {
    const pref = getPreference(opt.value);
    return {
      preferenceLabel: opt.label,
      preferenceType: opt.value,
      preferenceValue: Boolean(pref),
      incorporationType: pref ? pref.incorporation_type_mc_id : SOFT,
      allowHard: opt.meta.allowHard,
    };
  });

  const preferredCwPref = getPreference(NAMED_CW_PREF);
  const cwPreferenceExists =
    preferredCwPref && preferredCwPref.preference_value;
  const cwPreference = {
    cws: cwPreferenceExists
      ? preferredCwPref.preference_value.split(',').filter((val) => val)
      : [],
    incorporationType: cwPreferenceExists
      ? preferredCwPref.incorporation_type_mc_id
      : SOFT,
  };

  return {
    preferences,
    cwPreference,
  };
}

function transformRequest(preferenceData) {
  const { preferences, cwPreference } = preferenceData;

  const returnData = [];

  preferences
    .filter((pref) => pref.preferenceValue)
    .forEach((pref) => {
      returnData.push({
        preference_type_mc_id: pref.preferenceType,
        incorporation_type_mc_id: pref.incorporationType,
      });
    });

  if (cwPreference.cws.length) {
    returnData.push({
      preference_type_mc_id: NAMED_CW_PREF,
      preference_value: cwPreference.cws.toString(),
      incorporation_type_mc_id: cwPreference.incorporationType,
    });
  }

  return {
    preference: returnData,
  };
}

async function get(userName) {
  const url = `api/su/${userName}/preference`;
  const response = await axios.get(url);

  return transformResponse(response.data.results);
}

async function post(userName, preferences) {
  const url = `api/su/${userName}/preference`;
  const requestData = transformRequest(preferences);
  await axios.post(url, requestData);
}

export { get, post, transformResponse, transformRequest };
