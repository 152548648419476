<template>
  <div v-loading="loading">
    <v-toolbar v-if="showNav" flat color="primary" dark>
      <v-btn icon @click="goBack()">
        <v-icon>arrow_back</v-icon>
      </v-btn>
      <v-toolbar-title>{{ $route.name }}</v-toolbar-title>
    </v-toolbar>

    <v-list v-if="notifications.length > 0" subheader dense three-line>
      <div v-for="(notification, index) in notifications" :key="index">
        <v-list-tile @click="markAsSeen(notification)" :class="getSeenClass(notification)">
          <v-list-tile-content class="one-line">
            {{ notification.description }}
            <v-icon
              class="text-ttop"
              :color="getSeenIconColor(notification)"
              small
              v-if="notification.status === 'Elevated'">
              info
            </v-icon>
            <v-icon
              class="text-ttop"
              :color="getSeenIconColor(notification)"
              small
              v-else-if="notification.status === 'Halted'">
              warning
            </v-icon>
            <v-list-tile-sub-title>
              {{ formatDate(notification.issuedOn) }} -
              {{ formatTime(notification.issuedOn) }}
            </v-list-tile-sub-title>
          </v-list-tile-content>
        </v-list-tile>
        <v-divider v-if="index + 1 < notifications.length"></v-divider>
      </div>
    </v-list>

    <v-container fluid v-else>
      <v-layout class="grey--text" align-center justify-center> No Notifications </v-layout>
    </v-container>
    <v-container class="mt-4 text-xs-center">
      <router-link :to="{ name: 'Past Notifications' }" tag="span" v-if="!showPastNotifications">
        <v-btn flat color="warning" small class="ma-0">
          <v-icon class="mr-2">history</v-icon>
          Show Past Notifications
        </v-btn>
      </router-link>
    </v-container>
  </div>
</template>

<script>
import axios from 'axios';
import { notificationStatusUtils } from 'hcms-mc-utils';
import { getAll as getNotifications } from 'hcms-transforms/notifications';
import { getRouteObject } from 'utils/notificationUtils';
import { VList, VListTile, VListTileContent, VListTileSubTitle, VToolbar, VToolbarTitle } from 'vuetify/lib';

const CLOSED = notificationStatusUtils.toValue('Closed');
const HALTED = notificationStatusUtils.toValue('Halted');
const ELEVATED = notificationStatusUtils.toValue('Elevated');
const NEW = notificationStatusUtils.toValue('New');

export default {
  name: 'Notifications',

  components: {
    VList,
    VListTile,
    VListTileContent,
    VToolbar,
    VToolbarTitle,
    VListTileSubTitle,
  },

  props: {
    showPastNotifications: {
      type: Boolean,
    },
  },

  data() {
    return {
      loading: false,
      notifications: [],
    };
  },

  computed: {
    query() {
      return {
        notification_status: (this.showPastNotifications ? [CLOSED, HALTED] : [ELEVATED, NEW]).toString(),
        sort: 'event_date',
      };
    },
  },

  methods: {
    async init() {
      this.loading = true;
      try {
        const response = await getNotifications(this.query);
        this.notifications = response.data.results;
      } catch (err) {
        this.$notify(err, 'error');
      }
      this.loading = false;
    },

    async markAsSeen(notification) {
      if (!notification.id) {
        return;
      }

      try {
        const route = getRouteObject(notification);
        if (route) {
          this.$router.push(route);
        }
        if (!notification.seenDate && !this.showPastNotifications) {
          const url = `api/notification/${notification.id}/seen`;
          await axios.put(url, {});
          this.$notify('Notification has been acknowledged.', 'success');
          if (!route) {
            this.init();
          }
        }
      } catch (error) {
        this.$notify(error, 'error');
      }
    },

    getSeenClass(notification) {
      if (this.showPastNotifications || notification.seenDate) {
        return 'grey lighten-3 text--secondary';
      }
      return '';
    },

    getSeenIconColor(notification) {
      if (notification.seenDate) {
        switch (notification.status) {
          case 'Elevated':
            return 'warning';
          case 'Halted':
            return 'error';
          default:
            return '';
        }
      }
      return '';
    },
  },

  created() {
    this.init();
  },

  watch: {
    showPastNotifications: 'init',
  },
};
</script>
