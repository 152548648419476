import axios from 'axios';
import { emptyToNull, nullToEmpty } from 'global-utils';

function transformResponse(contact) {
  return nullToEmpty({
    contactId: contact.contact_id,
    contactName: contact.contact_name,
    contactType: contact.contact_type_mc_id,
    email: contact.email,
    isEmergencyContact: Boolean(contact.is_emergency_contact),
    sendEmailCopy: Boolean(contact.send_email),
    isPrivate: Boolean(contact.is_private),
    hasNokAccess: Boolean(contact.has_nok_access),

    street1: contact.street_1,
    street2: contact.street_2,
    postCode: contact.post_code,
    telephone1: contact.telephone_1,
    telephone2: contact.telephone_2,
  });
}

function transformRequest(contact) {
  return emptyToNull({
    contact: {
      contact_name: contact.contactName,
      contact_type_mc_id: contact.contactType,
      email: contact.email,
      is_emergency_contact: Boolean(contact.isEmergencyContact),
      is_private: Boolean(contact.isPrivate),
      send_email: Boolean(contact.sendEmailCopy),
      has_nok_access: Boolean(contact.hasNokAccess),

      street_1: contact.street1,
      street_2: contact.street2,
      post_code: contact.postCode,
      telephone_1: contact.telephone1,
      telephone_2: contact.telephone2,
    },
  });
}

async function getAll(params) {
  const url = '/api/contact';

  const response = await axios.get(url, {
    params,
  });

  return response.data.results.map(transformResponse);
}

async function get(id) {
  const url = `/api/contact/${id}`;

  const response = await axios.get(url);

  return transformResponse(response.data.contact);
}

async function post(contact) {
  const url = '/api/contact';
  const request = transformRequest(contact);

  const response = await axios.post(url, request);

  return response.data.contact_id;
}

async function put(id, contact) {
  const url = `/api/contact/${id}`;
  const request = transformRequest(contact);
  await axios.put(url, request);
}

export { transformResponse, transformRequest, getAll, get, post, put };
