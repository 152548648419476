import moment from 'moment';
import axios from 'axios';

import { emptyToNull } from 'global-utils';

function transformResponse(suspend) {
  return {
    suspendId: suspend.su_suspend_id,
    fromDate: suspend.start_date ? moment(suspend.start_date) : '',
    toDate: suspend.end_date ? moment(suspend.end_date) : '',
    attachments: suspend.attachments,
    reason: suspend.note,
  };
}

function transformRequest(suspend) {
  return emptyToNull({
    su_suspend: {
      su_suspend_id: suspend.suspendId,
      start_date: suspend.fromDate
        ? moment(suspend.fromDate).toISOString()
        : '',
      end_date: suspend.toDate ? moment(suspend.toDate).toISOString() : '',
      attachments: suspend.attachments,
      note: suspend.reason,
    },
  });
}

async function getAll(suUserName, params) {
  const url = `api/su/${suUserName}/suspend`;
  const response = await axios.get(url, { params });

  return response.data.results.map(transformResponse);
}

async function get(suUserName, suspensionId) {
  const url = `api/su/${suUserName}/suspend/${suspensionId}`;
  const response = await axios.get(url);
  return transformResponse(response.data.su_suspend);
}

async function post(suUserName, suspensionData) {
  const url = `api/su/${suUserName}/suspend`;
  const request = transformRequest(suspensionData);
  const response = await axios.post(url, request);

  return response.data.suspended_record_id;
}

async function put(suUserName, suspensionId, suspensionData) {
  const url = `api/su/${suUserName}/suspend/${suspensionId}`;
  const request = transformRequest(suspensionData);
  await axios.put(url, request);
}

async function cancelBookings(suUserName, suspensionId) {
  const url = `api/su/${suUserName}/suspend/${suspensionId}/cancel_bookings`;
  await axios.post(url);
}

export {
  transformResponse,
  transformRequest,
  getAll,
  get,
  post,
  put,
  cancelBookings,
};
