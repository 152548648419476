<template>
  <div class="date-modal">
    <v-date-picker
      @input="selectDate()"
      :first-day-of-week="firstDayOfWeek"
      :allowed-dates="allowedDates"
      v-model="selectedDate">
    </v-date-picker>
  </div>
</template>
<script>
import moment from 'moment';

import { VDatePicker } from 'vuetify/lib';

export default {
  name: 'DateModal',
  components: {
    VDatePicker,
  },
  props: ['date', 'allowedDates'],
  constants: {
    firstDayOfWeek: moment.localeData().firstDayOfWeek(),
  },
  data() {
    return {
      selectedDate: '',
    };
  },

  methods: {
    selectDate() {
      this.$emit('close', this.selectedDate);
    },
  },

  created() {
    this.selectedDate = this.date;
  },
};
</script>
<style lang="scss" scoped>
.date-modal {
  width: 290px;
}
</style>
