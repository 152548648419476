import moment from 'moment';
import axios from 'axios';
import { nullToEmpty, emptyToNull } from 'global-utils';

function transformResponse(address) {
  return nullToEmpty({
    addressId: address.address_id,
    street1: address.street_1,
    street2: address.street_2,
    postCode: address.post_code,
    contactPostCode: address.contact_post_code,
    isCancelled: address.is_cancelled,
    keysafePin: address.keysafe_pin,
    residencePhotos: address.residence_photos,
    entryInstructions: address.entry_instructions,

    effectiveFrom: moment(address.effective_from),
    effectiveUntil: address.effective_until
      ? moment(address.effective_until)
      : '',

    restoreFrom: address.restore_from ? moment(address.restore_from) : '',

    isBookingsHandled: Boolean(address.bookings_handled),
    handleBookingsUntil: moment(address.handle_bookings_until),
    hasTravelTimeImportIssue: address.is_tt_import_issue,

    createdBy: address.created_by,
    createdDate: address.created_date,
  });
}

function transformRequest(address) {
  return emptyToNull({
    address: {
      street_1: address.street1,
      street_2: address.street2,
      post_code: address.postCode,
      contact_post_code: address.contactPostCode,
      keysafe_pin: address.keysafePin,
      residence_photos: address.residencePhotos,
      entry_instructions: address.entryInstructions,
      effective_from: address.effectiveFrom,

      bookings_handled: Boolean(address.isBookingsHandled),
    },
  });
}

async function getAll(userName, params) {
  const url = `api/user/${userName}/address`;
  const response = await axios.get(url, { params });

  return response.data.results.map(transformResponse);
}

async function get(userName, addressId) {
  const url = `api/user/${userName}/address/${addressId}`;
  const response = await axios.get(url);

  return transformResponse(response.data.address);
}

async function post(userName, addressData) {
  const url = `api/user/${userName}/address`;
  const request = transformRequest(addressData);
  const response = await axios.post(url, request);

  return response.data.address_id;
}

async function put(userName, addressId, addressData) {
  const url = `api/user/${userName}/address/${addressId}`;
  const request = transformRequest(addressData);
  await axios.put(url, request);
}

export { getAll, get, post, put, transformResponse };
