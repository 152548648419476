<template>
  <div>
    <v-toolbar v-if="showNav" flat color="primary" dark>
      <v-icon>assignment</v-icon>
      <v-toolbar-title>Overview</v-toolbar-title>
      <v-spacer></v-spacer>
      <notification-bell></notification-bell>
    </v-toolbar>

    <v-layout align-center class="pa-3">
      <h5 class="headline">Upcoming Bookings</h5>
    </v-layout>
    <bookings v-loading="loading.upcomingBookings" v-model="upcomingBookings" />

    <v-layout align-center class="pa-3">
      <h5 class="headline">Past Bookings</h5>
    </v-layout>
    <bookings v-loading="loading.completedBookings" v-model="completedBookings" />
  </div>
</template>

<script>
import { getAll as getBookings } from 'hcms-transforms/booking';
import { get as getUser } from 'hcms-transforms/user_list';
import moment from 'moment';

import { bookingAllocationStatusUtils, bookingStatusUtils, titleUtils } from 'hcms-mc-utils';

import { VToolbar, VToolbarTitle } from 'vuetify/lib';

import Bookings from 'components/Bookings.vue';
import NotificationBell from 'components/NotificationBell.vue';

const { NEW, RESCHEDULE_REQUEST } = bookingStatusUtils.DICT;
const { ALLOCATED } = bookingAllocationStatusUtils.DICT;

export default {
  name: 'Overview',

  components: {
    Bookings,
    NotificationBell,
    VToolbar,
    VToolbarTitle,
  },

  data() {
    return {
      loading: {
        upcomingBookings: false,
        completedBookings: false,
      },
      upcomingBookings: [],
      completedBookings: [],
    };
  },

  computed: {
    baseParams() {
      return {
        service_user_user_name: this.userName,
        limit: 3,
        is_notified: true,
        booking_allocation_result: [ALLOCATED].toString(),
        booking_status: [NEW, RESCHEDULE_REQUEST].toString(),
      };
    },
  },

  methods: {
    formatDate(date) {
      return moment(date).format('MMM DD');
    },

    async getCareWorkerName(cwUserName) {
      if (!cwUserName) {
        return '';
      }
      const user = await getUser(cwUserName);
      return `${titleUtils.toLabel(user.title)} ${user.firstName} ${user.lastName}`;
    },

    async formatBooking(booking) {
      let cwName = '';
      const allocations = booking.bookingAllocation;
      if (allocations.length > 0) {
        cwName = await this.getCareWorkerName(allocations[0].cwUserName);
      }

      return {
        ...booking,
        bookingTitle: `${this.formatDate(booking.start)} - ${this.formatTime(booking.start)} - ${cwName}`,
      };
    },

    async getUpcomingBookings() {
      this.loading.upcomingBookings = true;

      try {
        const bookings = await getBookings({
          start_date_gte: moment(new Date()).toISOString(),
          sort: 'start_date',
          ...this.baseParams,
        });

        const bookingPromises = bookings.map(this.formatBooking, this);
        this.upcomingBookings = await Promise.all(bookingPromises);
      } catch (err) {
        this.$notify(err, 'error');
      }

      this.loading.upcomingBookings = false;
    },

    async getCompletedBookings() {
      this.loading.completedBookings = true;
      try {
        const bookings = await getBookings({
          start_date_lte: moment(new Date()).toISOString(),
          sort: '-start_date',
          ...this.baseParams,
        });

        const promises = bookings.map(this.formatBooking);
        this.completedBookings = await Promise.all(promises);
      } catch (err) {
        this.$notify(err, 'error');
      }

      this.loading.completedBookings = false;
    },

    init() {
      this.getUpcomingBookings();
      this.getCompletedBookings();
    },
  },

  created() {
    this.init();
  },
};
</script>
