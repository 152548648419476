import axios from 'axios';
import { emptyToNull, nullToEmpty } from 'global-utils';
import { transformResponse as transformBookingResponse } from '.';

function transformResponse(changeRequest) {
  const hasBookingObject = typeof changeRequest.new_booking === 'object';
  const newBooking = hasBookingObject
    ? transformBookingResponse(changeRequest.new_booking)
    : null;
  return nullToEmpty({
    changeRequestId: changeRequest.booking_change_request_id,
    prevBookingId: changeRequest.prev_booking,
    newBookingId: hasBookingObject
      ? newBooking.bookingId
      : changeRequest.new_booking,
    newBooking,
    requestType: changeRequest.booking_change_request_type_mc_id,
    requestStatus: changeRequest.booking_change_request_status_mc_id,
    reason: changeRequest.reason,
    adminRemark: changeRequest.admin_remark,
  });
}

function transformRequest(changeRequest) {
  return emptyToNull({
    booking_change_request_status_mc_id: changeRequest.requestStatus,
    admin_remark: changeRequest.adminRemark,
  });
}

async function getAll(queryParams) {
  const url = 'api/booking_change_request';
  const response = await axios.get(url, { params: queryParams });
  return response.data.results.map(transformResponse);
}

async function get(changeRequestId) {
  const url = `api/booking_change_request/${changeRequestId}`;
  const response = await axios.get(url);
  return transformResponse(response.data);
}

async function put(changeRequestId, changeRequest) {
  const url = `api/booking_change_request/${changeRequestId}`;
  const request = transformRequest(changeRequest);
  await axios.put(url, request);
}

export { getAll, get, put };

export default {
  transformRequest,
  transformResponse,
};
