<template>
  <div>
    <v-toolbar v-if="showNav" flat color="primary" dark>
      <v-icon>map</v-icon>
      <v-toolbar-title>Bookings</v-toolbar-title>
      <v-spacer></v-spacer>
      <notification-bell></notification-bell>
    </v-toolbar>
    <v-layout align-center class="my-2">
      <v-btn icon @click="nextDate(-1)">
        <v-icon>chevron_left</v-icon>
      </v-btn>

      <v-spacer></v-spacer>
      <v-toolbar-title class="ml-0" @click="changeBookingDate()">
        <v-btn icon>
          <v-icon>date_range</v-icon>
        </v-btn>
        {{ formatDate(bookingDate) }}
      </v-toolbar-title>
      <v-spacer></v-spacer>

      <v-btn icon @click="nextDate(1)">
        <v-icon>chevron_right</v-icon>
      </v-btn>
    </v-layout>
    <bookings v-loading="loading" v-model="bookingList" />
  </div>
</template>

<script>
import { getAll as getBookings } from 'hcms-transforms/booking';
import { get as getUser } from 'hcms-transforms/user_list';
import moment from 'moment';

import { VToolbar, VToolbarTitle } from 'vuetify/lib';

import { bookingAllocationStatusUtils, bookingStatusUtils, titleUtils } from 'hcms-mc-utils';

import Bookings from 'components/Bookings.vue';
import DateModal from 'views/Common/DateModal.vue';

import NotificationBell from 'components/NotificationBell.vue';

const TODAY = new Date();

const { NEW, RESCHEDULE_REQUEST } = bookingStatusUtils.DICT;
const { ALLOCATED } = bookingAllocationStatusUtils.DICT;

export default {
  name: 'BookingList',

  components: {
    Bookings,
    NotificationBell,
    VToolbar,
    VToolbarTitle,
  },

  data() {
    return {
      loading: false,
      bookingList: [],
      bookingDate: TODAY,
    };
  },

  computed: {
    selectedDate: {
      get() {
        return moment(this.bookingDate).format('YYYY-MM-DD');
      },
      set(value) {
        this.bookingDate = moment(value, 'YYYY-MM-DD');
      },
    },
  },

  methods: {
    async getCareWorkerName(cwUserName) {
      if (!cwUserName) {
        return '';
      }
      const user = await getUser(cwUserName);
      return `${titleUtils.toLabel(user.title)} ${user.firstName} ${user.lastName}`;
    },

    async formatBooking(booking) {
      let cwName = '';
      const allocations = booking.bookingAllocation;
      if (allocations.length > 0) {
        cwName = await this.getCareWorkerName(allocations[0].cwUserName);
      }

      return {
        ...booking,
        bookingTitle: `${this.formatTime(booking.start)} - ${this.formatTime(booking.end)} - ${cwName}`,
      };
    },

    async init() {
      this.loading = true;

      try {
        const bookings = await getBookings({
          start_date_gte: moment(this.bookingDate).startOf('day').toISOString(),
          start_date_lte: moment(this.bookingDate).endOf('day').toISOString(),
          service_user_user_name: this.userName,
          is_notified: true,
          booking_allocation_result: [ALLOCATED].toString(),
          booking_status: [NEW, RESCHEDULE_REQUEST].toString(),
          sort: 'start_time',
        });

        const bookingPromises = bookings.map(this.formatBooking, this);
        this.bookingList = await Promise.all(bookingPromises);
      } catch (err) {
        this.$notify(err, 'error');
      }

      this.loading = false;
    },

    nextDate(numDay) {
      this.bookingDate = moment(this.bookingDate).add(numDay, 'day');
      this.init();
    },

    async changeBookingDate() {
      const date = await this.$showModal({
        component: DateModal,
        props: {
          date: this.selectedDate,
        },
      });
      this.bookingDate = moment(date).format('YYYY-MM-DD');
      this.init();
    },
  },

  async created() {
    this.init();
  },
};
</script>
